<template>
    <div class="manage-jobs-container" v-if="hasMainLayoutInitialized">
        <section class="manage-jobs-container__content">
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  computed: {
    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.manage-jobs-container {
  margin: 0 20px;
  max-width: 1430px;
  height: 100%;
  position: relative;

  &__content {
    width: 100%;
    height: 100%;
  }
}

@include media-xs-max-width() {
  .manage-jobs-container {
    margin: 0;
  }
}
</style>
